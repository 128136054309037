import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { APIEndPoint } from '../core/constants/api-endpoints';

@Injectable()
export class ProfileService {
  //api_end_point:string="https://staging.mocingbird.com/api/v1/courses"//staging
  api_end_point: string = '/api/v1/certifications'; //local
  constructor(private http: HttpClient) {}

  getDeaLicenses(pageNo: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/dea' + '?page=' + pageNo);
  }
  deleteDeaLicense(id: any) {
    return this.http.delete(APIEndPoint.ENDPOINT_URL + '/api/v1/dea' + '/' + id);
  }
  getDeaLicense(id: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/dea' + '/' + id);
  }

  saveDeaLicense(
    state: any,
    license_number: any,
    initial_registration_date: any,
    issued_at: any,
    certificate_file_remote_url: any,
    user_file_id: any
  ) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/dea', {
      dea: {
        state: state,
        number: license_number,
        date_of_expiry: issued_at,
        flags: {
          initial_date: initial_registration_date,
        },
      },
      user_file_id: user_file_id,
    });
  }
  updateDeaLicense(
    id: any,
    state: any,
    license_number: any,
    initial_registration_date: any,
    issued_at: any,
    user_file_id: any
  ) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/dea' + '/' + id, {
      dea: {
        state: state,
        number: license_number,
        date_of_expiry: issued_at,
        flags: {
          initial_date: initial_registration_date,
        },
      },
      user_file_id: user_file_id,
    });
  }

  parseDataForCertificate(remote_url: any) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/parsers', {
      remote_url: remote_url,
    });
  }

  //bls
  getBlsLicenses(pageNo: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/bls' + '?page=' + pageNo);
  }

  getBlsLicense(id: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/bls' + '/' + id);
  }
  saveBlsLicense(
    license_number: any,
    date_of_expiry: any,
    certificate_file_remote_url: any,
    user_file_id: any
  ) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/bls', {
      bls: {
        number: license_number,
        date_of_expiry: date_of_expiry,
        //certificate_file_remote_url: certificate_file_remote_url,
      },
      user_file_id: user_file_id,
    });
  }

  updateBlsLicense(id: any, license_number: any, date_of_expiry: any, user_file_id: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/bls/' + id, {
      bls: {
        number: license_number,
        date_of_expiry: date_of_expiry,
      },
      user_file_id: user_file_id,
    });
  }
  deactivateAccount(obj: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/profiles/deactivate_account', obj);
  }
  deleteBlsLicense(id: any) {
    return this.http.delete(APIEndPoint.ENDPOINT_URL + '/api/v1/bls' + '/' + id);
  }
  sendNotifications(obj: any) {
    return this.http.put(
      APIEndPoint.ENDPOINT_URL + '/api/v1/profiles/update_notification_settings',
      obj
    );
  }
  updateAclsLicense(id: any, license_number: any, date_of_expiry: any, user_file_id: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/acls/' + id, {
      acls: {
        number: license_number,
        date_of_expiry: date_of_expiry,
      },
      user_file_id: user_file_id,
    });
  }
  getAclsLicenses(pageNo: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/acls' + '?page=' + pageNo);
  }
  deleteAclsLicense(id: any) {
    return this.http.delete(APIEndPoint.ENDPOINT_URL + '/api/v1/acls' + '/' + id);
  }
  getAclsLicense(id: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/acls' + '/' + id);
  }
  saveAclsLicense(
    license_number: any,
    date_of_expiry: any,
    certificate_file_remote_url: any,
    user_file_id: any
  ) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/acls', {
      acls: {
        number: license_number,
        date_of_expiry: date_of_expiry,
        //certificate_file_remote_url: certificate_file_remote_url,
      },
      user_file_id: user_file_id,
    });
  }

  updateAtlsLicense(id: any, license_number: any, date_of_expiry: any, user_file_id: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/atls/' + id, {
      atls: {
        number: license_number,
        date_of_expiry: date_of_expiry,
      },
      user_file_id: user_file_id,
    });
  }
  getAtlsLicenses(pageNo: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/atls' + '?page=' + pageNo);
  }
  deleteAtlsLicense(id: any) {
    return this.http.delete(APIEndPoint.ENDPOINT_URL + '/api/v1/atls' + '/' + id);
  }
  getAtlsLicense(id: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/atls' + '/' + id);
  }
  saveAtlsLicense(
    license_number: any,
    date_of_expiry: any,
    certificate_file_remote_url: any,
    user_file_id: any
  ) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/atls', {
      atls: {
        number: license_number,
        date_of_expiry: date_of_expiry,
        //certificate_file_remote_url: certificate_file_remote_url,
      },
      user_file_id: user_file_id,
    });
  }

  getControlledSubstanceRegistrationList(pageNo: any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/controlled_substance_registrations?page=' + pageNo
    );
  }

  getControlledSubstanceRegistration(id: any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/controlled_substance_registrations/' + id
    );
  }

  saveControlledSubstanceRegistration(csrObj: any, user_file_id: any) {
    let payload = {
      controlled_substance_registration: csrObj,
      user_file_id: user_file_id,
    };
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + '/api/v1/controlled_substance_registrations',
      payload
    );
  }

  updateControlledSubstanceRegistration(csrObj: any, user_file_id: any, id: any) {
    let payload = {
      controlled_substance_registration: csrObj,
      user_file_id: user_file_id,
    };
    return this.http.put(
      APIEndPoint.ENDPOINT_URL + '/api/v1/controlled_substance_registrations/' + id,
      payload
    );
  }

  deleteControlledSubstanceRegistration(id: any) {
    return this.http.delete(
      APIEndPoint.ENDPOINT_URL + '/api/v1/controlled_substance_registrations/' + id
    );
  }

  savePasswordInformation(current_password: any, password: any, password_confirmation: any) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/acls', {
      user: {
        password: password,
        password_confirmation: password_confirmation,
        current_password: current_password,
      },
    });
  }

  getProfileInfo() {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/profiles');
  }

  getProfileHistory(pageNo: any, term: any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/histories' + '?page=' + pageNo + '&term=' + term
    );
  }

  updatePassword(current_password: any, password: any, password_confirmation: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/profiles/update_password', {
      current_password: current_password,
      password: password,
      password_confirmation: password_confirmation,
    });
  }
  updateEmail(email: any, password: any, username: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/profiles', {
      email: email,
      current_password: password,
      username: username,
    });
  }
  updateGeneralInformation(
    first_name: any,
    last_name: any,
    phone_number: any,
    street_address: any,
    city: any,
    state: any,
    zip: any,
    email: any,
    birth_date: any,
    npi: any,
    member_organizations: any
  ) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/profiles', {
      first_name: first_name,
      last_name: last_name,
      phone_number: phone_number,
      street_address: street_address,
      city: city,
      state: state,
      zip: zip,
      // email: email,
      birth_date: birth_date,
      npi: npi,
      member_organizations: member_organizations,
    });
  }

  updateProfileImage(form_data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
      }),
    };
    return this.http.put(
      APIEndPoint.ENDPOINT_URL + '/api/v1/profiles',
      form_data
      // httpOptions
    );
  }

  //rs
  getRsLicenses(pageNo: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/rs' + '?page=' + pageNo);
  }
  getRsLicense(id: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/rs' + '/' + id);
  }
  saveRsLicense(
    license_number: any,
    date_of_expiry: any,
    certificate_file_remote_url: any,
    user_file_id: any
  ) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/rs', {
      rs: {
        number: license_number,
        date_of_expiry: date_of_expiry,
        //certificate_file_remote_url: certificate_file_remote_url,
      },
      user_file_id: user_file_id,
    });
  }
  updateRsLicense(id: any, license_number: any, date_of_expiry: any, user_file_id: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/rs/' + id, {
      rs: {
        number: license_number,
        date_of_expiry: date_of_expiry,
      },
      user_file_id: user_file_id,
    });
  }
  deleteRsLicense(id: any) {
    return this.http.delete(APIEndPoint.ENDPOINT_URL + '/api/v1/rs' + '/' + id);
  }
  //ms

  getMsLicenses(pageNo: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/ms' + '?page=' + pageNo);
  }
  getMsLicense(id: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/ms' + '/' + id);
  }
  saveMsLicense(
    license_number: any,
    date_of_expiry: any,
    certificate_file_remote_url: any,
    user_file_id: any
  ) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/ms', {
      ms: {
        number: license_number,
        date_of_expiry: date_of_expiry,
        //certificate_file_remote_url: certificate_file_remote_url,
      },
      user_file_id: user_file_id,
    });
  }
  updateMsLicense(id: any, license_number: any, date_of_expiry: any, user_file_id: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/ms/' + id, {
      ms: {
        number: license_number,
        date_of_expiry: date_of_expiry,
      },
      user_file_id: user_file_id,
    });
  }
  deleteMsLicense(id: any) {
    return this.http.delete(APIEndPoint.ENDPOINT_URL + '/api/v1/ms' + '/' + id);
  }

  //OPM
  //

  getOpmLicenses(pageNo: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/opm' + '?page=' + pageNo);
  }
  getOpmLicense(id: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/opm' + '/' + id);
  }
  saveOpmLicense(
    license_number: any,
    date_of_expiry: any,
    certificate_file_remote_url: any,
    user_file_id: any
  ) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/opm', {
      opm: {
        number: license_number,
        date_of_expiry: date_of_expiry,
        //certificate_file_remote_url: certificate_file_remote_url,
      },
      user_file_id: user_file_id,
    });
  }
  updateOpmLicense(id: any, license_number: any, date_of_expiry: any, user_file_id: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/opm/' + id, {
      opm: {
        number: license_number,
        date_of_expiry: date_of_expiry,
      },
      user_file_id: user_file_id,
    });
  }
  deleteOpmLicense(id: any) {
    return this.http.delete(APIEndPoint.ENDPOINT_URL + '/api/v1/opm' + '/' + id);
  }

  //ID
  //

  getIDLicenses(pageNo: any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/infectious_disease_licenses' + '?page=' + pageNo
    );
  }
  getIDLicense(id: any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/infectious_disease_licenses' + '/' + id
    );
  }
  saveIDLicense(
    license_number: any,
    date_of_expiry: any,
    certificate_file_remote_url: any,
    user_file_id: any
  ) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/infectious_disease_licenses', {
      infectious_disease: {
        number: license_number,
        date_of_expiry: date_of_expiry,
        //certificate_file_remote_url: certificate_file_remote_url,
      },
      user_file_id: user_file_id,
    });
  }
  updateIDLicense(id: any, license_number: any, date_of_expiry: any, user_file_id: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/infectious_disease_licenses/' + id, {
      infectious_disease: {
        number: license_number,
        date_of_expiry: date_of_expiry,
      },
      user_file_id: user_file_id,
    });
  }
  deleteIDLicense(id: any) {
    return this.http.delete(
      APIEndPoint.ENDPOINT_URL + '/api/v1/infectious_disease_licenses' + '/' + id
    );
  }

  saveBaseFile(params: any, user_file_id: any, type: any) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/' + type + '/base_files', {
      name: params?.name,
      description: params?.description,
      user_file_id: user_file_id,
      extra_data: params?.extra_data,
    });
  }
  updateBaseFile(id: any, params: any, type: any) {
    return this.http.put(APIEndPoint.ENDPOINT_URL + '/api/v1/' + type + '/base_files/' + id, {
      name: params?.name,
      description: params?.description,
      extra_data: params?.extra_data,
      user_file_id: params?.user_file_id,
    });
  }
  getBaseFiles(pageNo: any, type: any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/' + type + '/base_files' + '?page=' + pageNo
    );
  }
  getBaseFile(id: any, type: any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/' + type + '/base_files/' + id);
  }
  deleteBaseFile(id: any, type: any) {
    return this.http.delete(APIEndPoint.ENDPOINT_URL + '/api/v1/' + type + '/base_files/' + id);
  }

  searchReceiptsByKeyword(receiptSearchKeyword: any, pageNo: any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL +
        '/api/v1/receipts/base_files?page=' +
        pageNo +
        '&title=' +
        receiptSearchKeyword
    );
  }

  getUserAssociationList() {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/partner_organizations');
  }

  disconnectEduAccount() {
    return this.http.put(
      APIEndPoint.ENDPOINT_URL + '/api/v1/profiles/disconnect_education_account/sma',
      { dummy: true }
    );
  }
}
